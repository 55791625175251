import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";



import { Helmet } from "react-helmet";


import { Container, Wrapper, Row, Box } from "../../components/util";
import { ProductsCard, PartnerHelpFooter } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import './css/partnerChecklist.css';





import DroppahLogoSquare from "../../images/logos/Droppah/Droppah_Square.svg"
import IrdLogoSquare from "../../images/logos/IRD/IRD_Square.svg"
import XeroLogoSquare from "../../images/logos/Xero/Xero_Square.svg"
import MyHrLogoSquare from "../../images/logos/Other/MyHR_Square.svg";
import PowerBiLogoSquare from "../../images/logos/Other/PowerBI_Square.svg"
import AkahuLogoSquare from "../../images/logos/Other/Akahu_Square.svg"

import RssLogo from "../../images/graphics/logo_rss.svg";
import NotificationsLogo from "../../images/graphics/notifications.svg";
import RibbonLogo from "../../images/graphics/ribbon_sharp.svg";
import ThumbsUpLogo from "../../images/graphics/thumbs_up.svg";
import VideoCamLogo from "../../images/graphics/videocam.svg";


const ImageRow = styled(Row)`
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;

  img {
    height: 100px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }


  @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
    display: grid;
  }
`;


const PartnerSupportRow = styled(Row)`
  a {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
    background: #00ADEF1A;
    border-radius: 25px;
    font-weight: 600;
    text-decoration: none;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;

  }
  h5 {
    margin-top: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
  }

  @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
    > div {
      min-width: 340px;
      margin-bottom: 15px;
    }
  }
`;


const PartnerSuccessRow = styled(Row)`
 justify-content: space-evenly;


 @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
  > div {
    margin-bottom: 20px;
  }
 }

  div {
    width: 50%;
    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      width: 100%;
      > div {
        width: 90% !important;
      }
    }
  }
  h3 {
    text-align: center;
    min-height: 80px;
    color: #333333;
    &.-half-width {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      width: 100% !important;
      min-height: 35px;
    }
  }



  ol,
  ul {
    padding-left: 0px;
  }
`;

const IconContainer = styled(Container)`
  > div {
    > div {
      
    @media(max-width: ${(props) => props.theme.breakpoints.desktop}){
        margin-top: 5px;
      }
    }
  }

`;

const PartnerSuccessIconRow = styled(Row)`
  justify-content: space-evenly;
  > div {
    width: 33%;
    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      width: 100%;
      margin-bottom: 20px;
    }
    > div {
      color: white;
      width: 50%;
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

  }
`;

const HeaderText = styled(Box)`
  display: flex;
  flex-direction: column;

  > h2 {
    width: 50%;
    color: #F06E00;
    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      width: 80%;
      margin-bottom: 20px;
    }
  }
  > h3 {
    width: 75%;
    color: #333333;
  }
`;


const IntergrateBox = styled(Box)`
  h3 {
    width: 75%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

`


const PartnerResources = (props) => {
  const [checkListOne, setCheckedListOne] = useState([]);
  const [checkListTwo, setCheckedListTwo] = useState([]);

  const handleCheckListOne = (event) => {
    const item = event.target.value;
    if (event.target.checked) {
      setCheckedListOne(prevChecked => [...prevChecked, item]);
    } else {
      setCheckedListOne(prevChecked => prevChecked.filter(val => val !== item));
    }
  };


  const handleCheckListTwo = (event) => {
    const item = event.target.value;
    if (event.target.checked) {
      setCheckedListTwo(prevChecked => [...prevChecked, item]);
    } else {
      setCheckedListTwo(prevChecked => prevChecked.filter(val => val !== item));
    }
  };

  const isCheckedListOne = (item) =>
    checkListOne.includes(item) ? "checked-item" : "not-checked-item";

  const isCheckedListTwo = (item) =>
    checkListTwo.includes(item) ? "checked-item" : "not-checked-item";



  return (

    <Layout>
      <Seo
        title="Partner Resources | FlexiTime Partner"
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container className="border-bottom">
        <Wrapper stackGap={20}>
          <Box stackGap={70}>
            <h1 className="-textCenter">Resources for FlexiTime Partners</h1>
          </Box>
        </Wrapper>
      </Container>


      <Container>
        <Wrapper stackGap={40}>

          <HeaderText stackGap={20}>
            <h2 className="-textCenter">Meet FlexiTime's Suite of Workforce Management Products</h2>
            <h3 className="-textCenter">FlexiTime builds software that simplifies employment. We offer a range of integrated solutions to help businesses manage and pay their employees.</h3>
          </HeaderText>

        </Wrapper>
      </Container>

      <ProductsCard stackGap={20} paddingBottom={100}></ProductsCard>

      <Container className="-textCenter secondary">
        <Wrapper stackGap={20}>
          <IntergrateBox stackGap={20}
            centerOnMobile>
            <h3 className="-textCenter">Integrate with products like IRD and Xero to create a powerful ecosystem for managing employee time, payroll and accounts</h3>
            <ImageRow>
              <img
                src={DroppahLogoSquare}
                alt="Droppah"
              />
              <img
                src={XeroLogoSquare}
                alt="Xero"
              />
              <img
                src={MyHrLogoSquare}
                alt="MyHR"
              />
              <img
                src={IrdLogoSquare}
                alt="IRD"
              />
              <img
                src={PowerBiLogoSquare}
                alt="PowerBi"

              />
              <img
                src={AkahuLogoSquare}
                alt="Akahu"
              />
            </ImageRow>
          </IntergrateBox>
        </Wrapper>
      </Container>
      <Container className="-textCenter  border-bottom">
        <Wrapper stackGap={100}>
          <Box stackGap={20}>
            <h2 className="-textCenter" style={{
              marginBottom: '40px',
              color: '#F06E00'
            }}>Partner Support and Training</h2>
            <PartnerSupportRow>
              <div>
                <a
                  href="https://calendly.com/flexitime-partners"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VIP SUPPORT BOOKING
                </a>
                <h5>Screenshares, training and extended support call bookings.</h5>
              </div>
              <div>
                <a
                  href="https://support.flexitime.works/hc/en-us/sections/8595416475919-Partner-Portal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PARTNER SUPPORT CENTER
                </a>
                <h5>Articles and tips to support your partner experience.</h5>
              </div>
              <div>
                <a
                  href="https://app.productboard.com/?redirect_to=https://flexitime.productboard.com/portal/1-flexitime-feedback-and-ideas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SUBMIT FEATURE REQUESTS
                </a>
                <h5>Request and vote for feature requests on Productboard.</h5>
              </div>
            </PartnerSupportRow>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={50}>
          <Box stackGap={20}>
            <h2 className="-textCenter" style={{
              marginBottom: '40px',
              color: '#F06E00'
            }}>Get set up for success</h2>
            <PartnerSuccessRow>
              <div>
                <h3>New Partner Checklist</h3>
                <div className="checkList">
                  <div className="list-container">
                    <div className={isCheckedListOne("0")}>
                      <input value={"0"} type="checkbox" onChange={handleCheckListOne} />
                      <div className="htmlContainer">Get to know <a href='https://support.flexitime.works/hc/en-us' target='_blank'>your Partner Portal</a></div>
                    </div>
                    <div className={isCheckedListOne("1")}>
                      <input value={"1"} type="checkbox" onChange={handleCheckListOne} />
                      <div className="htmlContainer">Add & manage your own <a href='https://support.flexitime.works/hc/en-us/articles/8603054260623-My-Accounts-Creating-and-accessing-partner-accounts-demos' target='_blank'>free accounts</a></div>
                    </div>
                    <div className={isCheckedListOne("2")}>
                      <input value={"2"} type="checkbox" onChange={handleCheckListOne} />
                      <div className="htmlContainer"><a href='https://support.flexitime.works/hc/en-us/articles/8595335386895-Clients-Managing-clients-and-their-accounts' target='_blank'>Add your clients</a> to your Partner Portal</div>
                    </div>
                    <div className={isCheckedListOne("3")}>
                      <input value={"3"} type="checkbox" onChange={handleCheckListOne} />
                      <div className="htmlContainer"><a href='https://support.flexitime.works/hc/en-us/articles/8603173935631-Staff-Granting-portal-access-for-partner-staff-members' target='_blank' rel='noopener noreferrer'> Invite your staff</a> into your Partner Portal</div>
                    </div>
                    <div className={isCheckedListOne("4")}>
                      <input value={"4"} type="checkbox" onChange={handleCheckListOne} />
                      <div className="htmlContainer"><a href='https://calendly.com/flexitime-partners' target='_blank' rel='noopener noreferrer'>Book a training session </a> and get certified</div>
                    </div>
                    <div className={isCheckedListOne("5")}>
                      <input value={"5"} type="checkbox" onChange={handleCheckListOne} />
                      <div className="htmlContainer">Create <a href='https://support.flexitime.works/hc/en-us/articles/8603054260623-My-Accounts-Creating-and-accessing-partner-accounts-demos' target='_blank' rel='noopener noreferrer'> demo accounts </a>for you and your clients to learn the ropes</div>
                    </div>
                    <div className={isCheckedListOne("6")}>
                      <input value={"6"} type="checkbox" onChange={handleCheckListOne} />
                      <div className="htmlContainer"><a href='https://support.flexitime.works/hc/en-us/articles/8736252937487-Reporting-Consolidated-reporting-for-partners' target='_blank' rel='noopener noreferrer'> Create reports </a> using the consolidated reporting tool</div>
                    </div>
                  </div>
                </div>

              </div>
              <div>
                <h3 className="-half-width">Loading New Clients into PayHero Checklist</h3>
                <div className="checkList">
                  <div className="list-container">
                    <div className={isCheckedListTwo("0")}>
                      <input value={"0"} type="checkbox" onChange={handleCheckListTwo} />
                      <div className="htmlContainer">
                        <a href="https://support.flexitime.works/hc/en-us/articles/8595335386895-Clients-Managing-clients-and-their-accounts" target="_blank">
                          Get started
                        </a> with a new account
                      </div>
                    </div>
                    <div className={isCheckedListTwo("1")}>
                      <input value={"1"} type="checkbox" onChange={handleCheckListTwo} />
                      <div className="htmlContainer">
                        Import your client's <a href="https://support.payhero.co.nz/hc/en-us/articles/360002666496-Employee-Import" target="_blank" rel="noopener noreferrer">Employee Details</a> and <a href="https://support.payhero.co.nz/hc/en-us/articles/360002750355-Pay-History-Import" target="_blank" rel="noopener noreferrer">Pay History</a>, or use our automated conversion tools for clients coming from Ace & MYOB Desktop
                      </div>
                    </div>
                    <div className={isCheckedListTwo("2")}>
                      <input value={"2"} type="checkbox" onChange={handleCheckListTwo} />
                      <div className="htmlContainer">
                        Set up <a href="https://support.payhero.co.nz/hc/en-us/articles/360002749175-Pay-Cycles" target="_blank" rel="noopener noreferrer">Pay Cycles</a>, <a href="https://support.payhero.co.nz/hc/en-us/articles/360002666216-Teams" target="_blank" rel="noopener noreferrer">Teams</a>, <a href="https://support.payhero.co.nz/hc/en-us/articles/360002666196-Work" target="_blank" rel="noopener noreferrer">Work Types</a>, <a href="https://support.payhero.co.nz/hc/en-us/articles/360002750375-Pay-Items-Overview" target="_blank" rel="noopener noreferrer">Pay Items</a>
                      </div>
                    </div>
                    <div className={isCheckedListTwo("3")}>
                      <input value={"3"} type="checkbox" onChange={handleCheckListTwo} />
                      <div className="htmlContainer">
                        <a href="https://support.payhero.co.nz/hc/en-us/articles/360002749055-Inviting-Employees-to-use-PayHero" target="_blank" rel="noopener noreferrer">
                          Invite employees
                        </a> to use PayHero
                      </div>
                    </div>
                    <div className={isCheckedListTwo("4")}>
                      <input value={"4"} type="checkbox" onChange={handleCheckListTwo} />
                      <div className="htmlContainer">
                        Book a <a href="https://calendly.com/flexitime-partners" target="_blank" rel="noopener noreferrer">screen share meeting</a> with our support team to check the account set-up
                      </div>
                    </div>
                    <div className={isCheckedListTwo("5")}>
                      <input value={"5"} type="checkbox" onChange={handleCheckListTwo} />
                      <div className="htmlContainer">
                        Run a parallel pay to avoid any errors in the migration process
                      </div>
                    </div>
                    <div className={isCheckedListTwo("6")}>
                      <input value={"6"} type="checkbox" onChange={handleCheckListTwo} />
                      <div className="htmlContainer">
                        <a href="https://support.payhero.co.nz/hc/en-us/articles/360002665876-Inland-Revenue-Integration" target="_blank" rel="noopener noreferrer">
                          Integrate with Inland Revenue
                        </a> for automatic payday filing
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </PartnerSuccessRow>
          </Box>

        </Wrapper>
      </Container>
      <IconContainer className="-textCenter secondary">
        <Wrapper >
          <Box stackGap={20}>
            <PartnerSuccessIconRow className="-textCenter">
              <div>
                <img
                  src={ThumbsUpLogo}
                  alt="ThumbsUp"
                  height="50"
                />
                <Link
                  to="/partner-resources/marketing"
                >
                  MARKETING RESOURCES
                </Link>
                <div>
                  Access Flexitime material and branding to help grow your business.
                </div>
              </div>
              <div>
                <img
                  src={RibbonLogo}
                  alt="Ribbon"
                  height="50"
                />
                <Link
                  to="/partner-resources/certification-and-partner-status"
                >
                  CERTIFICATION AND STATUS LEVELS
                </Link>
                <div>
                  Gain product certifications, earn discounts and view agreements.
                </div>
              </div>
              <div>
                <img
                  src={VideoCamLogo}
                  alt="Video"
                  height="50"
                />
                <a
                  href="https://vimeo.com/showcase/10855209"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VIDEO RESOURCES
                </a>
                <div>
                  Get to know the products and catch up on the latest partner webinars.
                </div>
              </div>
            </PartnerSuccessIconRow>
          </Box>
          <Box stackGap={20}>
            <PartnerSuccessIconRow className="-textCenter">
              <div>
                <img
                  src={NotificationsLogo}
                  alt="Notification"
                  height="50"
                />
                <a
                  href="https://support.droppah.com/hc/en-us/sections/360000492275-Release-Notes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FOLLOW THE DROPPAH RELEASE NOTES
                </a>
              </div>
              <div>
                <img
                  src={RssLogo}
                  alt="Rss"
                  height="50"
                />
                <a
                  href="https://www.linkedin.com/showcase/payheronz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FOLLOW US ON LINKEDIN
                </a>
              </div>
              <div>
                <img
                  src={NotificationsLogo}
                  alt="Notification"
                  height="50"
                />
                <a
                  href="https://support.payhero.co.nz/hc/en-us/sections/360000731875-Release-Notes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FOLLOW THE PAYHERO RELEASE NOTES
                </a>
              </div>
            </PartnerSuccessIconRow>

          </Box>
        </Wrapper>
      </IconContainer>
      <PartnerHelpFooter></PartnerHelpFooter>
    </Layout>
  );
};

export default PartnerResources;
